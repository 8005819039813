import React, { useEffect, useState } from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { BsCalendar2Date } from "react-icons/bs";
import { GiCreditsCurrency } from "react-icons/gi";
import { MdOutlineCasino, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Col, Row } from "react-bootstrap";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import GenericPdfDownloader from "../../components/Common/GenericPdfdownloader";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import Loader from "../../components/Loader";
import DropDown from "../../components/Common/DropDown";
import InputField from "../../components/Common/InputField";
import { toast } from "react-toastify";
import Select from "react-select";
import { monthList } from "../../utils/monthList";

const monthOptions = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const sortByKey = (a, b) => {
	if (a.casino < b.casino) {
		return -1;
	}
	if (a.casino > b.casino) {
		return 1;
	}
	return 0;
};

const customStyles = {
	option: (provided, state) => ({
		...provided,
		borderColor: state.isFocused ? "blue" : "gray",
		backgroundColor: state.isFocused ? "#8651d6" : " #212121",
		":hover": {
			backgroundColor: state.isSelected ? "#8651d6" : "#8651d6"
		},
		":active": {
			backgroundColor: state.isSelected ? "#212121" : "#212121"
		}
	})
};

const Invoices = () => {
	const [casinoInfo, setCasinoInfo] = useState();
	const [selectedCasino, setSelectedCasino] = useState();
	const [yearOptions, setYearOptions] = useState([]);
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const [selectedMonth, setSelectedMonth] = useState(moment().subtract(1, "month").format("M"));
	const [errorMessage, setErrorMessage] = useState("");
	const [invoiceData, setInvoiceData] = useState();
	const [userInfo, setUserInfo] = useState();
	const [formData, setFormData] = useState();
	const [isReady, setIsReady] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isGetUserInfo, setIsGetUserInfo] = useState(false);
	const [initial, setInitial] = useState(true);
	const [width, setWidth] = useState();
	const [disabledExport, setDisabledExport] = useState(true);
	const [dropDown, setDropdown] = useState(false);
	const [yearDropDown, setYearDropdown] = useState(false);
	const [brandDropDown, setBrandDropdown] = useState(false);
	const [isAllBrand, setIsAllBrand] = useState(false);
	const [invoiceDate, setInvoiceDate] = useState(moment().month(selectedMonth).startOf("month"));
	const [dueDate, setDueDate] = useState(moment().add(15, "days"));
	const [brands, setBrands] = useState([]);

	const methods = useForm({
		defaultValues: {
			billing_period: `${moment().subtract(1, "month").format("MMM")} ${selectedYear}`,
			exchange_rate_domain: "www.xe.com",
			subtract_from_ggr: 0
		}
	});

	const {
		formState: { errors }
	} = methods;

	const brandIdentifier = (casino_brand_id) => {
		console.log(casino_brand_id);
		return brands.filter((brand) => brand?.brand_id === casino_brand_id);
	};

	useEffect(() => {
		const monthDigit = moment().subtract(1, "month").format("M");
		if (monthDigit == 12) {
			setSelectedYear(+moment().subtract(1, "year").format("YYYY"));
		}
	}, []);

	useEffect(() => {
		const monthAbvr = moment().month(selectedMonth).subtract(1, "month").format("MMM");

		methods.setValue("billing_period", `${monthAbvr} ${selectedYear}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedMonth, selectedYear]);

	useEffect(() => {
		axios
			.post(
				`${process.env.REACT_APP_LOGIN_API_DOMAIN}/api/userinfomation.php`,
				{
					userid: JSON.parse(localStorage.getItem("user-authorization-token"))?.userid,
					token: JSON.parse(localStorage.getItem("user-authorization-token"))?.token
				},
				{
					headers: {
						"Access-Control-Allow-Origin": "*",
						"Access-Control-Allow-Methods": "POST",
						"Content-Type": "multipart/form-data"
					}
				}
			)
			.then((response) => {
				if (response?.data?.status) {
					if (response?.status === 200) {
						const userId = JSON.parse(localStorage.getItem("user-authorization-token"))?.userid;
						const data = {
							...response.data,
							userId: userId
						};
						localStorage.setItem("userInformation", JSON.stringify(data));
						setInitial(false);
					} else {
						setErrorMessage(response?.data?.message);
					}
				} else {
					toast.error(response?.data?.error, { theme: "dark" });
					localStorage?.removeItem("user-authorization-token");
					localStorage?.removeItem("currentCasino");
					localStorage?.removeItem("userInformation");
				}
			})
			.catch((error) => {
				setErrorMessage(error?.message);
			})
			.finally(() => {
				// setGetUserLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		axios
			.post(
				`${process.env.REACT_APP_LOGIN_API_DOMAIN}/api/userinfomation.php`,
				{
					userid: JSON.parse(localStorage.getItem("user-authorization-token"))?.userid,
					token: JSON.parse(localStorage.getItem("user-authorization-token"))?.token
				},
				{
					headers: {
						"Access-Control-Allow-Origin": "*",
						"Access-Control-Allow-Methods": "POST",
						"Content-Type": "multipart/form-data"
					}
				}
			)
			.then((response) => {
				if (response?.data?.status) {
					if (response?.status === 200) {
						methods.setValue("invoice_number", Number(response?.data?.invoice_number));
						methods.setValue("po_number", Number(response?.data?.po_number));

						const brands = [];
						const allResponseCasinos = response?.data?.casino_information;
						allResponseCasinos.forEach((element) => {
							const indexOf = element?.casino_name?.indexOf("-");
							// console.log(true, element?.casino_name, element?.casino_name?.substring(0, indexOf)?.trim(), indexOf);
							const brandName = indexOf === -1 ? element?.casino_name?.trim() : element?.casino_name?.substring(0, indexOf)?.trim();
							const brandIndex = brands.findIndex((brand) => brand?.name === brandName);

							if (brandIndex === -1) {
								brands.push({
									name: brandName,
									casinos: [element]
								});
							} else {
								brands[brandIndex].casinos.push(element);
							}
						});
						let responseData = response?.data;
						responseData.brandInfo = brands;

						setUserInfo(responseData);
						setBrands(response.data?.casino_brands || []);
					} else {
						toast.error(response?.data?.error, { theme: "dark" });
						localStorage?.removeItem("user-authorization-token");
						localStorage?.removeItem("currentCasino");
						localStorage?.removeItem("userInformation");
					}
				} else {
					setErrorMessage(response?.data?.message);
				}
			})
			.catch((error) => {
				setErrorMessage(error?.message);
			})
			.finally(() => {
				// setGetUserLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isGetUserInfo]);

	useEffect(() => {
		// setCasinoInfo(userInfo?.casino_information);
		setCasinoInfo(userInfo?.brandInfo);
		// setSelectedCasino(userInfo?.casino_information[0]);
		// console.log(brands?.[0]);
		setSelectedCasino(brands?.[0]);
	}, [userInfo, brands]);

	// useEffect(() => {

	// }, [brands])

	useEffect(() => {
		let arr = [];
		for (let i = 2021; i <= new Date().getFullYear(); i++) {
			arr.push(i);
		}
		setYearOptions(arr);
	}, []);

	useEffect(() => {
		if (casinoInfo?.length > 0) {
			var casinoDetails = localStorage?.getItem("currentCasino");
			if (casinoDetails === null) {
				localStorage.setItem("currentCasino", JSON.stringify(casinoInfo[0]));
				casinoDetails = localStorage?.getItem("currentCasino");
			}
		}
	}, [casinoInfo, initial]);

	useEffect(() => {
		// console.log("selectedCasino",selectedCasino);
		// methods.setValue("company_name", selectedCasino?.casino_name);
		// methods.setValue("company_info", selectedCasino?.invoice_from);
		// methods.setValue("bill_to", selectedCasino?.bill_to);
		// methods.setValue("bank_account_or_wallet", selectedCasino?.bank_account_or_wallet_address);
		methods.setValue("company_name", selectedCasino?.brand);
		methods.setValue("company_info", selectedCasino?.invoice_from);
		methods.setValue("bill_to", selectedCasino?.bill_to);
		methods.setValue("bank_account_or_wallet", selectedCasino?.bank_account_or_wallet_address);

		const invoice_date = moment().year(selectedYear).month(selectedMonth).startOf("month");
		setInvoiceDate(invoice_date);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCasino, initial, selectedMonth, selectedYear]);

	useEffect(() => {
		const due_date = moment(invoiceDate).add(selectedCasino?.due_date, "days");
		setDueDate(due_date);
		// console.log(true, due_date)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invoiceDate]);

	const handleFormSubmit = async (values) => {
		// console.log(values, "values");
		const brandCasinos = userInfo?.casino_information.filter((casino) => casino?.casino_brand_id === selectedCasino?.brand_id);
		const casinosWithBrand = brandCasinos?.map((casino) => ({
			...casino,
			...(casino?.test_players && { test_players: JSON.parse(casino?.test_players) }),
			...selectedCasino
		}));
		setErrorMessage("");
		if (!dueDate) {
			setErrorMessage("Due Date is required");
			return;
		}
		setIsReady(false);
		setFormData(values);
		setLoading(true);
		let devPoint = /*window.location.href.includes("//playerreporting.com") || window.location.href.includes("localhost") ? "" : */ "dev-";

		devPoint = "staging-";
		if (window.location.href.includes("staging.playerreporting.com")) {
		}
		console.log(casinosWithBrand, "casinosWithBrand");

		await axios
			.post(`${process.env.REACT_APP_API_DOMAIN}/games/reports/${devPoint}monthly-game-summaries`, {
				// casinos: isAllBrand
				//     ? JSON.parse(localStorage.getItem("userInformation"))?.casino_information
				//     : [selectedCasino],
				// casinos: selectedCasino?.casinos,
				// casinos: brandCasinos,
				casinos: casinosWithBrand,
				start: `${selectedYear}-${selectedMonth.toString().padStart(2, "0")}-01T00:00:00Z`,
				end: `${selectedYear}-${selectedMonth.toString().padStart(2, "0")}-${new Date(selectedYear, selectedMonth, 0).getDate().toString().padStart(2, "0")}T23:59:59Z`,
				subtract_from_ggr: parseFloat(values?.subtract_from_ggr || 0)
			})
			.then((response) => {
				if (response.data?.status === 200) {
					if (response.data?.data?.length > 0) {
						setIsReady(true);
						setDisabledExport(false);
						const resInvoiceData = response.data?.data;
						// console.log(true, resInvoiceData);

						response.data?.data.map((data) => {
							return {
								...data,
								data: data.data?.sort(sortByKey)
							};
						});

						resInvoiceData[0].isAllBrand = isAllBrand;
						console.log(resInvoiceData, "resInvoiceData");
						setInvoiceData(resInvoiceData);
					} else {
						setDisabledExport(true);
						setErrorMessage("No Data Available");
					}
				} else {
					setDisabledExport(true);
					setErrorMessage(response.data?.message);
				}
			})
			.catch((error) => {
				setErrorMessage(error.message);
				setDisabledExport(true);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		setWidth(window.innerWidth);
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	// useEffect(() => {
	//     setSelectedCasino(JSON.parse(localStorage.getItem("currentCasino")));
	// }, []);

	return (
		<FormProvider {...methods}>
			<div className="form_box_wp casino_chart mb_30">
				<div className="datepicker_wp mb_30">
					<div className="row">
						<div className="col-lg-6">
							<div className="date_picker_wrapper">
								<div className="row">
									<div className="col-lg-6">
										<div className="form_input_wp">
											<label className="form_input_label">
												<div className="form_input_icon">
													<BsCalendar2Date size={20} />
												</div>
												Select Month
											</label>
											<DropDown
												placeholder="Select Month"
												options={monthOptions}
												dataKey=""
												isSelected={monthOptions[selectedMonth - 1]}
												activeOption={monthOptions[selectedMonth - 1]}
												activeOptionId={monthOptions[selectedMonth - 1]}
												setOption={(data, index) => {
													setSelectedMonth(index + 1 > 9 ? index + 1 : `0${index + 1}`);
													methods.setValue("billing_period", monthOptions[index]);
												}}
											/>
											{/* <div
                                                className={`dropdown_wp currency_dropdown ${
                                                    dropDown ? "active_dropdown" : ""
                                                }`}>
                                                <button
                                                    type="button"
                                                    className="sec_btn dropdown_btn transparent_btn"
                                                    onClick={() =>
                                                        width <= 991 && setDropdown((prev) => !prev)
                                                    }
                                                    onMouseEnter={() => width >= 991 && setDropdown(true)}
                                                    onMouseLeave={() =>
                                                        width >= 991 && setDropdown(false)
                                                    }>
                                                    {monthOptions[selectedMonth - 1]}
                                                    <span className="dropdown_icon">
                                                        <MdOutlineKeyboardArrowDown size={22} />
                                                    </span>
                                                </button>

                                                <ul className="dropdown_list">
                                                    {monthOptions?.map((month, index) => (
                                                        <li
                                                            key={index}
                                                            onClick={() => {
                                                                setSelectedMonth(
                                                                    index + 1 > 9
                                                                        ? index + 1
                                                                        : `0${index + 1}`
                                                                );
                                                                methods.setValue(
                                                                    "billing_period",
                                                                    monthOptions[index]
                                                                );
                                                            }}>
                                                            {month}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div> */}
										</div>
									</div>

									<div className="col-lg-6">
										<div className="form_input_wp">
											<label className="form_input_label">
												<div className="form_input_icon">
													<BsCalendar2Date size={20} />
												</div>
												Select Year
											</label>
											<DropDown
												placeholder="Select Month"
												options={yearOptions}
												dataKey=""
												isSelected={selectedYear}
												activeOption={selectedYear}
												activeOptionId={selectedYear}
												setOption={(data, index) => {
													setSelectedYear(data);
												}}
											/>
											{/* <div
                                                className={`dropdown_wp currency_dropdown ${
                                                    yearDropDown ? "active_dropdown" : ""
                                                }`}>
                                                <button
                                                    type="button"
                                                    className="sec_btn dropdown_btn transparent_btn"
                                                    onClick={() =>
                                                        width <= 991 && setYearDropdown((prev) => !prev)
                                                    }
                                                    onMouseEnter={() =>
                                                        width >= 991 && setYearDropdown(true)
                                                    }
                                                    onMouseLeave={() =>
                                                        width >= 991 && setYearDropdown(false)
                                                    }>
                                                    {selectedYear}
                                                    <span className="dropdown_icon">
                                                        <MdOutlineKeyboardArrowDown size={22} />
                                                    </span>
                                                </button>

                                                <ul className="dropdown_list">
                                                    {yearOptions?.map((year, index) => (
                                                        <li
                                                            key={index}
                                                            onClick={() => setSelectedYear(year)}>
                                                            {year}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div> */}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
						<div className="col-lg-4">
							<div className="form_input_wp">
								<label className="form_input_label">
									<div className="form_input_icon">
										<GiCreditsCurrency size={20} />
									</div>
									Select Brand
								</label>
								{/* <DropDown
                                    placeholder="Select Brand"
                                    options={casinoInfo}
                                    dataKey="casino_name"
                                    isSelected={selectedCasino}
                                    activeOption={selectedCasino?.casino_name}
                                    activeOptionId={selectedCasino?.casino_name}
                                    setOption={(data, index) => {
                                        setSelectedCasino(data);
                                        // methods.setValue("company_name");
                                    }}
                                /> */}
								{/* <DropDown
                                    placeholder="Select Brand"
                                    options={casinoInfo}
                                    dataKey="name"
                                    isSelected={selectedCasino}
                                    activeOption={selectedCasino?.name}
                                    activeOptionId={selectedCasino?.name}
                                    setOption={(data, index) => {
                                        setSelectedCasino(data);
                                        // methods.setValue("company_name");
                                    }}
                                /> */}
								<DropDown
									placeholder="Select Brand"
									options={brands}
									dataKey="brand"
									isSelected={selectedCasino?.brand}
									activeOption={selectedCasino?.brand}
									activeOptionId={selectedCasino?.brand}
									setOption={(data, dataIndex) => {
										// console.log(data)
										setSelectedCasino(data);
										// console.log(brands.filter(brand => brand?.brand_id === data.brand_id));
										// handleChange(
										//     index,
										//     {
										//         target: {
										//             value: data.brand_id || "",
										//             name: "casino_brand_id",
										//         },
										//     }
										// );
									}}
								/>
							</div>
							{/* <div className="form_input_wp brand_checkbox_wp">
                                <input
                                    type="checkbox"
                                    id="brand_checkbox"
                                    checked={isAllBrand}
                                    onChange={() => setIsAllBrand((prev) => !prev)}
                                />
                                <label className="form_input_label" htmlFor="brand_checkbox">
                                    Invoice for all brands
                                </label>
                            </div> */}
						</div>
					</div>
				</div>
			</div>

			<form
				className="invoice_main"
				onSubmit={methods.handleSubmit(handleFormSubmit)}
			>
				<div className="invoice_form">
					<div className="form_box">
						{/* <h4 className="form_title h4_title">Invoice</h4> */}

						<Row>
							<Col lg={4}>
								<InputField
									type="text"
									label="Company Name"
									labelIcon={<LiaFileInvoiceSolid size={20} />}
									className="form_input"
									placeholder="Enter Company Name"
									name="company_name"
									register={methods.register}
									validationSchema={{
										required: "Company Name is required"
									}}
									errorMessage={errors?.company_name?.message}
								/>
								<InputField
									type="textarea"
									name="company_info"
									id="company_info"
									placeholder="Enter Company Info"
									register={methods.register}
									// validationSchema={{
									//     required: "Company Info is required",
									// }}
									errorMessage={errors?.company_info?.message}
								/>
							</Col>

							<Col lg={4}>
								<InputField
									type="textarea"
									label="Bill To"
									labelIcon={<LiaFileInvoiceSolid size={20} />}
									name="bill_to"
									id="bill_to"
									className="form_input big_textarea"
									placeholder="Enter Bill To"
									register={methods.register}
									// validationSchema={{
									//     required: "Bill To is required",
									// }}
									errorMessage={errors?.bill_to?.message}
								/>
							</Col>

							<Col lg={4}>
								<InputField
									type="textarea"
									label="Bank Account or Wallet"
									labelIcon={<LiaFileInvoiceSolid size={20} />}
									name="bank_account_or_wallet"
									className="form_input big_textarea"
									placeholder="Enter Bank Account or Wallet"
									register={methods.register}
									// validationSchema={{
									//     required: "Bank Account or Wallet is required",
									// }}
									errorMessage={errors?.bank_account_or_wallet?.message}
								/>
							</Col>

							<Col lg={3}>
								<InputField
									type="number"
									step={1}
									label="Invoice Number"
									labelIcon={<LiaFileInvoiceSolid size={20} />}
									placeholder="Enter Invoice Number"
									name="invoice_number"
									register={methods.register}
									validationSchema={{
										required: "Invoice Number is required"
									}}
									errorMessage={errors?.invoice_number?.message}
								/>
							</Col>

							<Col lg={3}>
								<div className="date_picker_wrapper">
									<label className="form_input_label">
										<div className="form_input_icon">
											<BsCalendar2Date size={20} />
										</div>
										Invoice Date
									</label>
									<DateRangePicker
										initialSettings={{
											minYear: moment().year(),
											maxYear: moment().add(1, "years").year(),
											minDate: `01/01/${moment().year()}`,
											maxDate: `31/12/${moment().add(1, "years").year()}`,
											singleDatePicker: true,
											showDropdowns: true,
											locale: {
												format: "DD/MM/YYYY"
											}
										}}
										onEvent={(event, picker) => {
											if (event.type === "apply") {
												setInvoiceDate(picker?.endDate);
											}
										}}
									>
										<input
											type="text"
											className="form_input"
											value={moment(invoiceDate).format("DD/MM/YYYY")}
										/>
									</DateRangePicker>
									{!invoiceDate && <p style={{ color: "red" }}>Invoice Date is required</p>}
								</div>
							</Col>
							<Col lg={3}>
								<div className="date_picker_wrapper">
									<label className="form_input_label">
										<div className="form_input_icon">
											<BsCalendar2Date size={20} />
										</div>
										Due Date
									</label>

									<DateRangePicker
										initialSettings={{
											minYear: moment().year(),
											maxYear: moment().add(1, "years").year(),
											minDate: `01/01/${moment().year()}`,
											maxDate: `31/12/${moment().add(1, "years").year()}`,
											singleDatePicker: true,
											showDropdowns: true,
											locale: {
												format: "DD/MM/YYYY"
											},
											ranges: {
												"15 Days": [
													moment(invoiceDate).toDate(),
													moment(invoiceDate)
														// .add(1, "M")
														// .startOf("month")
														.add(15, "days")
														.toDate()
												],
												"30 Days": [
													moment(invoiceDate).toDate(),
													moment(invoiceDate)
														// .add(1, "M")
														// .startOf("month")
														.add(30, "days")
														.toDate()
												],
												"60 Days": [
													moment(invoiceDate).toDate(),
													moment(invoiceDate)
														// .add(1, "M")
														// .startOf("month")
														.add(60, "days")
														.toDate()
												]
											}
										}}
										onEvent={(event, picker) => {
											if (event.type === "apply") {
												setDueDate(picker?.endDate);
											}
										}}
									>
										<input
											type="text"
											className="form_input"
											value={moment(dueDate).format("DD/MM/YYYY")}
										/>
									</DateRangePicker>
									{errors?.due_date && <p style={{ color: "red" }}>{errors?.due_date?.message}</p>}
								</div>
							</Col>
							<Col lg={3}>
								<InputField
									type="text"
									label="Exchange Rate Domain"
									labelIcon={<LiaFileInvoiceSolid size={20} />}
									placeholder="Enter Exchange Rate Domain"
									name="exchange_rate_domain"
									register={methods.register}
									validationSchema={{
										required: "Exchange rate domain is required"
									}}
									errorMessage={errors?.exchange_rate_domain?.message}
								/>
							</Col>
							<Col lg={3}>
								<InputField
									type="number"
									label="Subtract from GGR"
									labelIcon={<LiaFileInvoiceSolid size={20} />}
									placeholder="Enter Subtract from GGR"
									name="subtract_from_ggr"
									register={methods.register}
									min={0}
									errorMessage={errors?.subtract_from_ggr?.message}
								/>
							</Col>
							<Col lg={3}>
								<label className="form_input_label">
									<div className="form_input_icon">
										<MdOutlineCasino size={20} />
									</div>
									Bonus Deduction Period
								</label>

								<Select
									className="form__input-select"
									isDisabled
									isMulti
									value={
										selectedCasino?.bonus_applicable_months?.length
											? selectedCasino?.bonus_applicable_months?.split(",")?.map((item) => {
													return { value: item, label: item };
											  })
											: []
									}
									styles={customStyles}
									options={monthList}
								/>
							</Col>
							<Col lg={3}>
								<InputField
									type="text"
									label="Billing Period"
									labelIcon={<LiaFileInvoiceSolid size={20} />}
									placeholder="Enter Billing Period"
									name="billing_period"
									readOnly
									register={methods.register}
									validationSchema={{
										required: "Please select month"
									}}
									errorMessage={errors?.billing_period?.message}
								/>
							</Col>

							<Col lg={3}>
								<InputField
									type="text"
									label="P.O.Number"
									labelIcon={<LiaFileInvoiceSolid size={20} />}
									placeholder="Enter P.O.Number"
									name="po_number"
									readOnly
									register={methods.register}
									validationSchema={{
										required: "P.O.Number is required"
									}}
									errorMessage={errors?.po_number?.message}
								/>
							</Col>
							{errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

							<Col lg={12}>
								{/* {console.log(false, ...invoiceData)} */}
								<div className="form_btn text-center">
									<GenericPdfDownloader
										rootElementId="invoice"
										loading={loading}
										setIsGetUserInfo={setIsGetUserInfo}
										invoiceData={{
											...invoiceData,
											...formData,
											dueDate,
											invoiceDate,
											selectedCasino
										}}
										invoices={invoiceData}
										isAllBrand={invoiceData?.[0]?.isAllBrand ? true : false}
										isReady={isReady}
										disabledExport={disabledExport}
									/>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</form>
		</FormProvider>
	);
};

export default Invoices;

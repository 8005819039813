import InputField from "../Common/InputField";
import { MdOutlineCasino } from "react-icons/md";

const TestPlayers = ({ handleFormData, inputFields, setInputFields, setTestUserModal }) => {
	const addInputField = () => {
		setInputFields([
			...inputFields,
			{
				userId: "",
				username: ""
			}
		]);
	};

	const removeInputFields = (index) => {
		const rows = [...inputFields];
		rows.splice(index, 1);
		setInputFields(rows);
	};

	const handleChange = (index, e) => {
		const { name, value } = e.target;
		const list = [...inputFields];
		list[index][name] = value;
		setInputFields(list);
	};

	const onsubmit = (e) => {
		e.preventDefault();
		const inputValues = inputFields.map((item) => ({ username: item.username, userId: item.userId }));
		handleFormData("test_players", JSON.stringify(inputValues));
		setTestUserModal(false);
	};

	return (
		<>
			<form
				method="post"
				onSubmit={onsubmit}
			>
				<div className="">
					{inputFields?.map((data, index) => {
						const { username, userId } = data;
						return (
							<div
								key={index}
								className="bonus_deduction_games_form"
							>
								<div className={`form-group w-100 bonus_deduction_games_form_wp`}>
									<InputField
										type="text"
										label={"User Id"}
										labelIcon={<MdOutlineCasino size={20} />}
										name="userId"
										onChange={(e) => handleChange(index, e)}
										value={userId}
										required={true}
									/>
									<InputField
										type="text"
										label={"Username"}
										labelIcon={<MdOutlineCasino size={20} />}
										name="username"
										onChange={(e) => handleChange(index, e)}
										value={username}
									/>
								</div>
								{/* {inputFields?.length > 1 && ( */}
								<button
									type="button"
									className="sec_btn"
									onClick={() => removeInputFields(index)}
								>
									x
								</button>
								{/* )} */}
							</div>
						);
					})}
				</div>
				<div className="d-flex w-100 justify-content-sm-end justify-content-center mb-4">
					<button
						className="sec_btn"
						type="button"
						onClick={addInputField}
						disabled={inputFields.filter((item) => !item.userId).length}
					>
						Add player
					</button>
				</div>
				<div className="d-flex gap-4 justify-content-sm-end justify-content-center w-100">
					<button
						onClick={() => setTestUserModal(false)}
						className="sec_btn"
						type="button"
					>
						Close
					</button>
					<button
						type="submit"
						className="sec_btn"
					>
						Save
					</button>
				</div>
			</form>
		</>
	);
};

export default TestPlayers;

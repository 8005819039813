import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import Loader from "../../Loader";

const AllGamesChart = ({ startDate, endDate, currency }) => {
	const [chartdata, setChartdata] = useState();
	const [monthlist, setMonthlist] = useState([]);
	const [betsWon, setBetsWon] = useState([]);
	const [betsLost, setBetsLost] = useState([]);
	const [netProfit, setNetProfit] = useState([]);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const getData = () => {
		const casino = JSON.parse(localStorage?.getItem("currentCasino"));
		const taxRate = casino?.tax_rate.replace(/%/g, "");

		setLoading(true);

		axios
			.get(
				`${process.env.REACT_APP_API_DOMAIN}/games/reports/all-games-transactions?token=${casino?.casino_token}&casino=${casino?.casino_key}&currency=${
					currency === "All" ? "" : currency
				}&start=${startDate}T00:00:00Z&end=${endDate}T23:59:59Z&tax_rate=${taxRate || 0}`
			)
			.then((response) => {
				if (response.data.status === 200) {
					setChartdata(response.data?.response);
				} else {
					setErrorMessage(response?.data?.message);
				}
			})
			.catch((error) => {
				setErrorMessage(error.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		setErrorMessage("");
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, endDate, currency]);

	useEffect(() => {
		var monthllistarr = [];
		var betwonarr = [];
		var betlostarr = [];
		var netProfitArr = [];
		if (chartdata) {
			// eslint-disable-next-line array-callback-return
			chartdata.map((val, i) => {
				var cudate = val.date;
				monthllistarr.push(cudate);
				betwonarr.push(parseFloat(val.totalDebit).toFixed(2));
				betlostarr.push(parseFloat(val.totalCredit).toFixed(2));
				netProfitArr.push(parseFloat(val.netProfit).toFixed(2));
			});

			if (!monthllistarr.length && !betwonarr.length && !betlostarr.length && !netProfitArr.length) {
				setMonthlist([]);
				setBetsWon([]);
				setBetsLost([]);
				setNetProfit([]);
				setErrorMessage("There are no records to display");
			} else {
				setErrorMessage("");
				setMonthlist(monthllistarr);
				setBetsWon(betwonarr);
				setBetsLost(betlostarr);
				setNetProfit(netProfitArr);
			}
		}
	}, [chartdata]);

	const playerChartoption = {
		options: {
			chart: {
				type: "bar",
				height: 350,
				toolbar: {
					show: false
				}
			},
			plotOptions: {
				bar: {
					colors: {
						ranges: [
							{ from: -100, to: -46, color: "#F15B46" },
							{ from: -45, to: 0, color: "#FEB019" }
						]
					},
					columnWidth: "80%"
				}
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				width: [1, 1, 4]
			},
			yaxis: {
				padding: {
					left: 0,
					right: 0
				},
				axisTicks: {
					show: true
				},
				axisBorder: {
					// offsetX: -12,
					show: true,
					color: "#008FFB"
				},
				title: {
					text: "Total Won and Lost",
					style: {
						color: "#008FFB"
					}
				},
				labels: {
					formatter: function (y) {
						return parseFloat(y).toFixed(2) + " USD";
					},
					style: {
						colors: "#008FFB"
					}
				},
				tooltip: {
					enabled: true
				}
			},
			xaxis: {
				// type: 'datetime',
				categories: monthlist,
				labels: {
					rotate: -90,
					style: {
						colors: "#008FFB"
					}
				}
			},
			tooltip: {
				fixed: {
					enabled: true,
					position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
					offsetY: 30,
					offsetX: 60
				},
				theme: "dark"
			},
			legend: {
				horizontalAlign: "left",
				offsetX: 20,
				labels: {
					colors: "#fff"
				},
				markers: {
					offsetX: -3,
					offsetY: 2
				},
				itemMargin: {
					horizontal: 10
				}
			},
			grid: {
				padding: {
					left: 0,
					right: 0
				},

				yaxis: {
					lines: {
						show: true
					}
				}
			}
		},
		series: [
			{
				name: "Bets Won",
				data: betsWon
			},
			{
				name: "Bets Lost",
				data: betsLost
			},
			{
				name: "Net Profit",
				data: netProfit
			}
		]
	};

	return (
		<>
			<h5 className="h5_title text-center">All Games</h5>
			{errorMessage ? (
				<p style={{ textAlign: "center", color: "red" }}> {errorMessage}</p>
			) : (
				<Chart
					{...playerChartoption}
					type="bar"
					width="100%"
					height="500px"
				/>
			)}
			{loading ? <Loader /> : ""}
		</>
	);
};

export default AllGamesChart;
